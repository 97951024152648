import React, { useState } from "react";
import axios from "axios";

function Search() {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 2) { // Fetch suggestions if query length > 2
      try {
        const response = await axios.get(`/search?q=${value}`);
        setSuggestions(response.data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]); // Clear suggestions if query is short
    }
  };

  const handleSelect = (name) => {
    setQuery(name); // Set the selected suggestion
    setSuggestions([]); // Clear suggestions
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        value={query}
        onChange={handleSearch}
        placeholder="Search for teams or leagues..."
        className="search-input"
      />
      {suggestions.length > 0 && (
        <ul className="suggestions">
          {suggestions.map((item) => (
            <li key={item._id} onClick={() => handleSelect(item.name)}>
              {item.name} <span>({item.type})</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Search;
