import React from "react";
import {useNavigate} from "react-router-dom";

function Footer(){
    
    const navigate = useNavigate();

    async function handleClick(event){
        navigate(event.target.id);
    }

    return(

        <div className="footer"> 

        <h5>© Netscore 2025</h5>

        <div className="footer-info">
            <h5 onClick={handleClick} id="misc">Privacy Policy & ToS</h5>        
            <h5 onClick={handleClick} id="contact"><a className="contact-us-footer" href="mailto:netscore134@gmail.com">Contact Us</a></h5>
        </div>

        </div>
    )
}

export default Footer;