import React from "react";
import {useLocation} from "react-router-dom";

function Ads(){

    const location = useLocation();

    if(location.pathname === "/misc"){
        return null;
    }

    return <div className="ads"/>;
 
}

export default Ads;