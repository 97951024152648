import { useState, useEffect } from "react";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [consentGiven, setConsentGiven] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === null) {
      setShowBanner(true);
    } else {
      setConsentGiven(consent === "true");
      if (consent === "true") {
        loadTrackingScripts();
      }
    }
  }, []);

  const loadTrackingScripts = () => {
    // Load Google Ads tracking script
    const googleScript = document.createElement("script");
    googleScript.src = "https://www.googletagmanager.com/gtag/js?id=YOUR-GOOGLE-ID";
    googleScript.async = true;
    document.head.appendChild(googleScript);

    const googleInlineScript = document.createElement("script");
    googleInlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'YOUR-GOOGLE-ID');
    `;
    document.head.appendChild(googleInlineScript);

    // Load Microsoft Clarity script
    const clarityScript = document.createElement("script");
    clarityScript.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "q0iforjwov");
    `;
    document.head.appendChild(clarityScript);
  };

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
    setConsentGiven(true);
    loadTrackingScripts();
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowBanner(false);
    setConsentGiven(false);
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner">
      <p>
        We use cookies to improve your experience. By accepting, you allow us to
        use tracking technologies. <a href="/misc">Learn more</a>.
      </p>
      <div className="button-group">
        <button onClick={handleAccept} className="accept">Accept</button>
        <button onClick={handleDecline} className="decline">Decline</button>
      </div>
    </div>
  );
};

export default CookieConsent;