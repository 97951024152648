import React from 'react';

function Misc(){
    
    return (
        
        <div className="misc-wrapper">

            <div className="about-wrapper"> 

            
                <p>Welcome to netscore.live, your ultimate destination for real-time sports updates, live scores, and in-depth game analysis. We are passionate about sports and committed to keeping fans like you informed, engaged, and entertained with the latest happenings across the world of athletics.</p>
                <h3>What Sets Us Apart?</h3>
                <ul>
                    <li>Live Updates: Instantaneous score updates so you never miss a moment.</li>
                    <li>Comprehensive Coverage: From local leagues to global championships, we track it all.</li>
                    <li>User-Friendly Design: A sleek and intuitive platform that works seamlessly across devices.</li>
                </ul>
                
                <h3>Our Mission</h3>
                <p>Our mission is simple: to be the most reliable and engaging platform for sports enthusiasts around the world. We aim to connect fans to the action, fostering a vibrant community that celebrates the thrill, passion, and spirit of sports.</p>

                <h3>Join In the Action!</h3>
                <p>Whether you're a die-hard fan, a casual observer, or a stats enthusiast, netscore.live is your go-to resource for all things sports. Stay informed, stay connected, and never miss a game-changing moment.</p>
            </div>

            <div className="tos-wrapper">
                
                <h1>Privacy Policy</h1>
                <p><strong>Effective Date:</strong> February 1, 2025</p>
                <p>Welcome to <strong>NetScore.live</strong> ("we," "our," or "us"). Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your information.</p>
                
                <h3>1. Information We Collect</h3>
                <ul>
                    <li><strong>Personal Data:</strong> We do not collect personally identifiable information unless voluntarily provided.</li>
                    <li><strong>Usage Data:</strong> Includes IP address, browser type, device info, and pages visited.</li>
                    <li><strong>Cookies & Tracking:</strong> We use cookies for analytics (Microsoft Clarity) and advertising (Google Ads).</li>
                </ul>
                
                <h3>2. How We Use Your Information</h3>
                <p>We use collected data to analyze site performance, serve ads, and improve user experience.</p>
                
                <h3>3. Third-Party Services</h3>
                <ul>
                    <li><strong>Google Ads:</strong> Uses cookies for personalized advertising (<a href="https://adssettings.google.com/">Manage Ads</a>).</li>
                    <li><strong>Microsoft Clarity:</strong> Records user interactions (<a href="https://privacy.microsoft.com/en-us/privacystatement">Privacy Policy</a>).</li>
                </ul>
                
                <h3>4. Your Rights</h3>
                <p>You can disable cookies in your browser and request data deletion via email.</p>
                
                <h3>5. Changes to This Policy</h3>
                <p>We may update this Privacy Policy from time to time.</p>
                
                <h3>6. Contact</h3>
                <p>For any questions, contact us at <a href="mailto:netscore134@gmail.com">netscore134@gmail.com</a>.</p>
                
                <hr/>
                
                <h1>Terms of Service</h1>
                <p><strong>Effective Date:</strong> February 1, 2025</p>
                <p>By using <strong>NetScore.live</strong>, you agree to comply with these Terms of Service.</p>
                
                <h3>1. Use of Website</h3>
                <ul>
                    <li>You must be at least 13 years old to use this site.</li>
                    <li>You agree not to use the site for illegal activities.</li>
                </ul>
                
                <h3>2. Intellectual Property</h3>
                <p>All content is owned by NetScore.live or used under license. You may not reproduce or distribute content without permission.</p>
                
                <h3>3. Third-Party Ads & Tracking</h3>
                <p>We use Google Ads and Microsoft Clarity for analytics and advertising.</p>
                
                <h3>4. Limitation of Liability</h3>
                <p>We are not responsible for any damages resulting from your use of this site.</p>
                
                <h3>5. Changes to These Terms</h3>
                <p>We reserve the right to update these Terms of Service at any time.</p>
                
                <h3>6. Contact</h3>
                <p>For questions, contact us at <a href="mailto:netscore134@gmail.com">netscore134@gmail.com</a>.</p>

            </div>

            
        </div>
    );

}

export default Misc;