import React, {useState, useEffect} from "react";

function Stats({events, status, teams}){


    if(events){

    const homeTeam = teams.home.id;
    const awayTeam = teams.away.id;
    let score = [0,0];

    function setScore(id){
        if(id === homeTeam) score[0]++;
        else score[1]++;
        const out = score[0] + "-" + score[1];
        return out;
    }
    
    function setCard(detail){
        if (detail === "Yellow Card") return <div className="yellow-card"/>;
        else return <div className="red-card"/>;
    }

    function createCircle(color){
        if(color === "red")
            return <div className="circle-red"/>
        
        else
            return <div className="circle-green"/>
        
    }
    
    


        return( 
            <div className="block-5">
            
                <div className="text-minor">EVENTS</div>

                <div className="stats-wrapper">
                            {events?.map((item) => (
                                <div>
                                    {/* <div className="fent"/> */}
                                    <div className="stat-wrapper">

                                    <span className="stat-time">{item.time.elapsed}'</span>

                                        <div className="stat-l">
                                            {item.team.id === homeTeam?
                                            <div>


                                            {item.type === "subst"? 

                                                <div>

                                                    <div className="sub-in">{createCircle("green")}{item.player.name}</div>
                                                    {item.assist.name === ""? null: <div className="sub-out">{createCircle("red")}{item.assist.name}</div>}

                                                </div>:

                                                <div className="stat-player">   
                                                
                                                    <span className="stat-player-name">{item.player.name}</span>
                                                    {item.assist.name === ""? null: <span className="stat-assist">{item.assist.name}</span>}

                                                </div>  
                                                
                                            }

                                            </div>: null}
                                        </div>

                                    


                                    <div className="stat-score">
                                    {item.type === "Goal"? setScore(item.team.id) : null}
                                    {item.type === "Card"? setCard(item.detail) : null}
                                    {item.type === "subst"? <div className="text-minor">Sub</div> : null}
                                    </div>


                                    <div className="stat-r">
                                        {item.team.id === awayTeam?
                                        <div>

                                        {item.type === "subst"? 

                                            <div>

                                                <div className="sub-in">{createCircle("green")}{item.player.name}</div>
                                                {item.assist.name === ""? null: <div className="sub-out">{createCircle("red")}{item.assist.name}</div>}

                                            </div>:

                                            <div className="stat-player">   
                                            
                                                <span className="stat-player-name">{item.player.name}</span>
                                                {item.assist.name === ""? null: <span className="stat-assist">{item.assist.name}</span>}

                                            </div>  
                                        }

                                        </div>: null}
                                    </div>
                                    
                                        
                                    </div>
                                    <div className="fent"/>  
                                </div>
                            ))}
                            
                        
                            <div>

                            {status !== "FT"? null :
                                <div className="stat-ft">
                                    
                                    <div className="stat-time">FT</div>
                                    <div className="stat-score">{score[0]}-{score[1]}</div>

                                </div>
                            }
                            </div>
                    </div>

            </div>
        );
    }
}

export default Stats;