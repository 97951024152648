import {useNavigate, useLocation} from "react-router-dom";

function Sidebar() {

    const navigate = useNavigate();
    const location = useLocation();

    function handleClick(event){
        navigate("/leagues/" + event.target.id);
    }

    if(location.pathname === "/misc"){
        return null;
    }

    else {

    return(
            <div className="sidebar-wrapper">

        <h3 className="mj">Major leagues</h3>

        <div onClick={handleClick} className="sidebar-item" id="39">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="39" src="https://media.api-sports.io/football/leagues/39.png"/></div>

        <div id="39"  className="sidebar-item-inner">
            <span id="39" >Premier League</span>
            <span id="39"  className="text-minor">England</span>
        </div>

        </div>
        
        <div onClick={handleClick} className="sidebar-item" id="78">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="78" src="https://media.api-sports.io/football/leagues/78.png"/></div>

        <div id="78"  className="sidebar-item-inner">
            <span id="78" >Bundesliga</span>
            <span id="78" className="text-minor">Germany</span>
        </div>

        </div>

        <div onClick={handleClick} className="sidebar-item" id="61">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="61" src="https://media.api-sports.io/football/leagues/61.png"/></div>

        <div id="61"  className="sidebar-item-inner">
            <span id="61" >Ligue 1</span>
            <span id="61"  className="text-minor">France</span>
        </div>

        </div>

        <div onClick={handleClick} className="sidebar-item" id="135">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="135" src="https://media.api-sports.io/football/leagues/135.png"/></div>

        <div id="135"  className="sidebar-item-inner">
            <span id="135" >Serie A</span>
            <span id="135"  className="text-minor">Italy</span>
        </div>

        </div>

        <div onClick={handleClick} className="sidebar-item" id="140">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="140" src="https://media.api-sports.io/football/leagues/140.png"/></div>

        <div id="140"  className="sidebar-item-inner">
            <span id="140" >La Liga</span>
            <span id="140"  className="text-minor">Spain</span>
        </div>

        </div>

        <div onClick={handleClick} className="sidebar-item" id="88">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="88" src="https://media.api-sports.io/football/leagues/88.png"/></div>

        <div id="88"  className="sidebar-item-inner">
            <span id="88" >Eredivisie</span>
            <span id="88"  className="text-minor">Netherlands</span>
        </div>


        </div>

        <div onClick={handleClick} className="sidebar-item" id="71">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="71" src="https://media.api-sports.io/football/leagues/71.png"/></div>

        <div id="71"  className="sidebar-item-inner">
            <span id="71" >Serie A</span>
            <span id="71"  className="text-minor">Brazil</span>
        </div>


        </div>
        
        <div onClick={handleClick} className="sidebar-item" id="94">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="94" src="https://media.api-sports.io/football/leagues/94.png"/></div>

        <div id="94"  className="sidebar-item-inner">
            <span id="94" >Primeira Liga</span>
            <span id="94"  className="text-minor">Portugal</span>
        </div>


        </div>

        <div onClick={handleClick} className="sidebar-item" id="128">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="128" src="https://media.api-sports.io/football/leagues/128.png"/></div>

        <div id="128"  className="sidebar-item-inner">
            <span id="128" >Liga Profesional</span>
            <span id="128"  className="text-minor">Argentina</span>
        </div>


        </div>

        <div onClick={handleClick} className="sidebar-item" id="197">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="197" src="https://media.api-sports.io/football/leagues/197.png"/></div>

        <div id="197"  className="sidebar-item-inner">
            <span id="197" >Super League</span>
            <span id="197"  className="text-minor">Greece</span>
        </div>


        </div>

        <div onClick={handleClick} className="sidebar-item" id="235">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="235" src="https://media.api-sports.io/football/leagues/235.png"/></div>

        <div id="235"  className="sidebar-item-inner">
            <span id="235" >Premier League</span>
            <span id="235"  className="text-minor">Russia</span>
        </div>


        </div>

        
        <div onClick={handleClick} className="sidebar-item" id="318">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="318" src="https://media.api-sports.io/football/leagues/318.png"/></div>

        <div id="318"  className="sidebar-item-inner">
            <span id="318" >1 Division</span>
            <span id="318"  className="text-minor">Cyprus</span>
        </div>


        </div>

        <div onClick={handleClick} className="sidebar-item" id="2">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="2" src="https://media.api-sports.io/football/leagues/2.png"/></div>

        <div id="2" className="sidebar-item-inner">
            <span id="2">UEFA Champions League</span>
            <span id="2" className="text-minor">World</span>
        </div>

        </div>

        <div onClick={handleClick} className="sidebar-item" id="3">
            
        <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="3" src="https://media.api-sports.io/football/leagues/3.png"/></div>

        <div id="3" className="sidebar-item-inner">
            <span id="3">Europa League</span>
            <span id="3" className="text-minor">Europe</span>
        </div>

        </div>

        <div onClick={handleClick} className="sidebar-item" id="848">
            
            <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="848" src="https://media.api-sports.io/football/leagues/848.png"/></div>
    
            <div id="848" className="sidebar-item-inner">
                <span id="848">Conference League</span>
                <span id="848" className="text-minor">Europe</span>
            </div>
    
            </div>

            <div onClick={handleClick} className="sidebar-item" id="667">
            
            <div className="status-sidebar"><img className="white-background-logo" onClick={handleClick} id="667" src="https://media.api-sports.io/football/leagues/667.png"/></div>
    
            <div id="667" className="sidebar-item-inner">
                <span id="667">Club Friendlies</span>
                <span id="667" className="text-minor">World</span>
            </div>
    
            </div>
        
        </div>       
        
    )}
}

export default Sidebar;